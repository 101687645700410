"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const types_1 = require("api/documents/purchases/procurements/types");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const index_1 = require("api/index");
const helpers_1 = require("../helpers");
const store_1 = require("core/store");
const addeditProcurement_1 = require("../store/addeditProcurement");
const queryString_1 = require("core/helpers/queryString");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const useAddEditProcurement = (props) => {
    const [state, dispatch] = (0, addeditProcurement_1.useProcurementReducer)();
    const [showApprovalButton, setShowApprovalButton] = (0, react_1.useState)(false);
    const [showDeclineButton, setShowDeclineButton] = (0, react_1.useState)(false);
    const [showSuspendButton, setShowSuspendButton] = (0, react_1.useState)(false);
    const [showDeclineMessage, setShowDeclineMessage] = (0, react_1.useState)(false);
    const [showSuspendMessage, setShowSuspendMessage] = (0, react_1.useState)(false);
    const [showSingleApprovalAlert, setShowSingleApprovalAlert] = (0, react_1.useState)(false);
    const [documentLock, setDocumentLock] = (0, react_1.useState)(null);
    const [showRevertMessage, setShowRevertMessage] = (0, react_1.useState)(false);
    const [isApprover, setIsApprover] = (0, react_1.useState)(false);
    const [deletingItems, setDeletingItems] = (0, react_1.useState)([]);
    const [isDeletingItemsLoading, setIsDeletingItemsLoading] = (0, react_1.useState)(false);
    const isMounted = (0, react_1.useRef)(false);
    const lockInterval = (0, react_1.useRef)();
    const [openedCompanySidebar, setOpenedCompanySidebar] = (0, react_1.useState)(false);
    const [deletingFiles, setDeletingFiles] = (0, react_1.useState)([]);
    const [showDeleteFileAlert, setShowDeleteFileAlert] = (0, react_1.useState)(null);
    const [procurementFileType, setProcurementFileType] = (0, react_1.useState)('');
    const [itemsDirty, setItemsDirty] = (0, react_1.useState)(false);
    const [hasItems, setHasItems] = (0, react_1.useState)(false);
    const [procurementsParamsIds, setProcurementsParamsIds] = (0, react_1.useState)({ procurementIds: [], meta: store_1.initialMeta });
    const [buttonNavigate, setButtonNavigate] = (0, react_1.useState)(addeditProcurement_1.initialProcurementNavigate);
    const [listingParams, setListingParams] = (0, react_1.useState)('?');
    const [showActuallyDoneMessage, setShowActuallyDoneMessage] = (0, react_1.useState)(false);
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const setCompanySideBar = (isOpen) => {
        setOpenedCompanySidebar(() => {
            if (isOpen)
                window.addEventListener('click', closeProcurementCompanySideBar, true);
            return isOpen;
        });
    };
    const closeProcurementCompanySideBar = (event) => {
        const isCompany = document.querySelector('#company-menu')?.contains(event.target);
        if (isCompany)
            return;
        window.removeEventListener('click', closeProcurementCompanySideBar, true);
        setOpenedCompanySidebar(false);
    };
    const getProcurement = async () => {
        dispatch({ type: 'SEND_PROCUREMENT_REQUEST' });
        const procurement = await index_1.ProcurementsApi.getProcurement(props.procurementId, true);
        dispatch({ type: 'DONE_PROCUREMENT_REQUEST', payload: procurement });
    };
    const getTemplate = async (procurementId) => {
        dispatch({ type: 'SEND_TEMPLATE_REQUEST' });
        const contentData = await index_1.ProcurementsApi.getProcurementTemplate(procurementId);
        dispatch({ type: 'DONE_TEMPLATE_REQUEST', payload: contentData });
    };
    const updateProcurementTemplate = async (values) => {
        const params = (0, helpers_1.transformProcurementTemplateParams)(values);
        dispatch({ type: 'SEND_TEMPLATE_UPDATE' });
        const contentData = await index_1.ProcurementsApi.updateProcurementTemplate(props.procurementId, params);
        dispatch({ type: 'DONE_TEMPLATE_UPDATE', payload: contentData });
        if (state.data.status !== types_1.ProcurementStatuses.DRAFT && contentData.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS)
            getProcurement().then();
        return contentData;
    };
    const generateProcurementTemplate = async (id) => {
        dispatch({ type: 'SEND_TEMPLATE_GENERATE' });
        const procurement = await index_1.ProcurementsApi.generateProcurementTemplate(id);
        dispatch({ type: 'DONE_TEMPLATE_GENERATE', payload: procurement });
        return procurement;
    };
    const deleteProcurementItem = async (ids, id) => {
        setIsDeletingItemsLoading(true);
        const response = await index_1.ProcurementsApi.deleteProcurementItem(id, { ids: ids });
        if (response.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS)
            setDeletingItems([]);
        setIsDeletingItemsLoading(false);
        return response;
    };
    const sendForVerify = async (id) => {
        setShowSingleApprovalAlert(false);
        dispatch({ type: 'SEND_PROCUREMENT_VERIFY' });
        const response = await index_1.ProcurementsApi.sendForVerify(id);
        if (response.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS)
            await getProcurement();
        dispatch({ type: 'DONE_PROCUREMENT_VERIFY', payload: { errors: response.errors } });
        return response;
    };
    const sendApproval = async (id) => {
        dispatch({ type: 'SEND_PROCUREMENT_APPROVE' });
        const procurement = await index_1.ProcurementsApi.sendApproval(id, true);
        dispatch({ type: 'DONE_PROCUREMENT_APPROVE', payload: procurement });
        return procurement;
    };
    const declineProcurement = async (message, procurementId) => {
        dispatch({ type: 'SEND_PROCUREMENT_DECLINE' });
        setShowDeclineMessage(false);
        const procurement = await index_1.ProcurementsApi.declineProcurement(message, procurementId, true);
        dispatch({ type: 'DONE_PROCUREMENT_DECLINE', payload: procurement });
        return procurement;
    };
    const suspendProcurement = async (message, procurementId) => {
        dispatch({ type: 'SEND_PROCUREMENT_SUSPEND' });
        setShowSuspendMessage(false);
        const procurement = await index_1.ProcurementsApi.suspendProcurement(message, procurementId, true);
        dispatch({ type: 'DONE_PROCUREMENT_SUSPEND', payload: procurement });
        return procurement;
    };
    const actuallyDoneProcurement = async (message, procurementId) => {
        dispatch({ type: 'SEND_PROCUREMENT_ACTUALLY_DONE' });
        setShowActuallyDoneMessage(false);
        const procurement = await index_1.ProcurementsApi.actuallyDoneProcurement(message, procurementId, true);
        dispatch({ type: 'DONE_PROCUREMENT_ACTUALLY_DONE', payload: procurement });
        return { errors: procurement.errors, status: procurement.status };
    };
    const attachSignature = async (values, id) => {
        const params = (0, helpers_1.transformSignatureParams)(values);
        dispatch({ type: 'SEND_ATTACH_SIGNATURE' });
        const procurement = await index_1.ProcurementsApi.attachSignature(id, params, true);
        dispatch({ type: 'DONE_ATTACH_SIGNATURE', payload: procurement });
        return procurement;
    };
    const attachSignedDocument = async (values, id) => {
        const params = (0, helpers_1.transformSignatureDocument)(values);
        dispatch({ type: 'SEND_ATTACH_SIGNATURE_DOCUMENT' });
        const procurement = await index_1.ProcurementsApi.attachSignedDocument(id, params, true);
        dispatch({ type: 'DONE_ATTACH_SIGNATURE_DOCUMENT', payload: procurement });
        return procurement;
    };
    const sendSignatureDocument = async (id) => {
        dispatch({ type: 'SEND_SIGNATURE_DOCUMENT' });
        const procurement = await index_1.ProcurementsApi.sendSignatureDocument(id, true);
        dispatch({ type: 'DONE_SIGNATURE_DOCUMENT', payload: procurement });
        return procurement;
    };
    const updateProcurement = async (values, id) => {
        const params = (0, helpers_1.transformProcurementParams)(values, false);
        dispatch({ type: 'SEND_PROCUREMENT_UPDATE' });
        const procurement = await index_1.ProcurementsApi.updateProcurement(id, params, true);
        dispatch({ type: 'DONE_PROCUREMENT_UPDATE', payload: procurement });
        return procurement;
    };
    const createProcurement = async (values) => {
        const params = (0, helpers_1.transformProcurementParams)(values, true);
        dispatch({ type: 'SEND_PROCUREMENT_CREATING' });
        const procurement = await index_1.ProcurementsApi.createProcurement(params, true);
        dispatch({ type: 'DONE_PROCUREMENT_CREATING', payload: procurement });
        if (procurement.data !== null) {
            getLastFirstElementStatus(procurement.data.id);
            navigate(`/documents/purchases/procurements/${procurement.data.id}/edit`);
        }
        return procurement;
    };
    const documentLockIn = async (id) => {
        const lockInParams = { moduleName: state.data.moduleType, moduleId: id };
        dispatch({ type: 'SEND_PROCUREMENT_LOCK' });
        const lockData = await index_1.LockApi.lockIn(lockInParams);
        if (lockData.data !== null)
            setDocumentLock(lockData.data);
        dispatch({ type: 'DONE_PROCUREMENT_LOCK' });
        if (!lockInterval.current && lockData.data !== null && lockData.data.timeDistance) {
            lockInterval.current = setInterval(() => { documentLockIn(id); }, lockData.data.timeDistance - 10000);
        }
        return { errors: lockData.errors, status: lockData.status };
    };
    const documentLockOut = async (id) => {
        clearInterval(lockInterval.current);
        lockInterval.current = undefined;
        const lockInParams = { moduleName: state.data?.moduleType, moduleId: id };
        dispatch({ type: 'SEND_PROCUREMENT_LOCKOUT' });
        const lockResponse = await index_1.LockApi.lockOut(lockInParams);
        if (lockResponse.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS)
            setDocumentLock(null);
        dispatch({ type: 'DONE_PROCUREMENT_LOCKOUT' });
        return { status: lockResponse.status, errors: lockResponse.errors };
    };
    const revertProcurement = async (revertComment, id) => {
        setShowRevertMessage(false);
        dispatch({ type: 'SEND_PROCUREMENT_REVERT' });
        const procurement = await index_1.ProcurementsApi.revertProcurement(id, { revertComment }, true);
        dispatch({ type: 'DONE_PROCUREMENT_REVERT', payload: procurement });
        return { status: procurement.status, errors: procurement.errors };
    };
    const addUpdateTreasuryBudgetArticle = async (procurementItemId, treasuryBudgetArticleId) => {
        dispatch({ type: 'SEND_UPDATE_TREASURY_BUDGET_ARTICLE_REQUEST' });
        const response = await index_1.ProcurementsApi.addUpdateTreasuryBudgetArticle({ items: [{ id: procurementItemId, treasuryBudgetArticleId }] });
        dispatch({ type: 'DONE_UPDATE_TREASURY_BUDGET_ARTICLE_REQUEST' });
        return response;
    };
    const addUpdateTreasuryProject = async (procurementItemId, treasuryProjectId) => {
        dispatch({ type: 'SEND_UPDATE_TREASURY_PROJECT_REQUEST' });
        const response = await index_1.ProcurementsApi.addUpdateTreasuryBudgetArticle({ items: [{ id: procurementItemId, treasuryProjectId }] });
        dispatch({ type: 'DONE_UPDATE_TREASURY_PROJECT_REQUEST' });
        return response;
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    const deleteProcurementFile = async (id) => {
        setDeletingFiles(prev => [...prev, id]);
        const procurementFile = await index_1.ProcurementsApi.deleteProcurementFile(props.procurementId, { fileUids: [id] });
        setDeletingFiles(prev => prev.filter(el => el !== id));
        return { errors: procurementFile.errors, status: procurementFile.status };
    };
    const getProcurementIds = async (values) => {
        const onlyMine = localStorage.getItem('procurementOnlyMine') || undefined;
        const procurementParams = (0, helpers_1.transformProcurementParamsToFilterParams)({ ...values, onlyMine });
        const itemsParams = (0, helpers_1.getItemsParamsFromUrl)(location.search);
        const procurements = await index_1.ProcurementsApi.getProcurements(procurementParams);
        if (procurements.data !== null && procurements.meta) {
            const responseIds = { procurementIds: procurements.data.map(el => {
                    return {
                        id: el.id,
                        status: el.status
                    };
                }), meta: procurements.meta };
            const searchParams = (0, queryString_1.stringify)({ ...(0, helpers_1.transformFilterParamsToUrl)(values), ...itemsParams });
            setListingParams(searchParams || '?');
            setProcurementsParamsIds(responseIds);
            return responseIds;
        }
        return null;
    };
    const nextProcurement = async () => {
        setButtonNavigate(prevState => ({ ...prevState, next: true, isLoading: true }));
        const currentIndex = procurementsParamsIds.procurementIds.findIndex(el => el.id === props.procurementId);
        const nextProcurement = procurementsParamsIds.procurementIds[currentIndex + 1];
        const { pagination } = procurementsParamsIds.meta;
        const params = (0, helpers_1.transformFilterParamsToUrl)((0, helpers_1.transformUrlToFilterParams)(location.search));
        // const CorrectionOrEdit = Number(params.statusId) === ProcurementStatuses.CORRECTED
        //   || Number(params.statusId) === ProcurementStatuses.CORRECTION_PROCESS ? '/corrections' : '/edit'
        if (nextProcurement) {
            const CorrectionOrEdit = nextProcurement.status === types_1.ProcurementStatuses.CORRECTED
                || nextProcurement.status === types_1.ProcurementStatuses.CORRECTION_PROCESS
                ? '/corrections'
                : '/edit';
            const itemsParams = (0, helpers_1.getItemsParamsFromUrl)(location.search);
            navigate('/documents/purchases/procurements/' + nextProcurement.id + CorrectionOrEdit + (0, queryString_1.stringify)({ ...params, ...itemsParams }));
            const lastElement = pagination.currentPage === pagination.totalPages && procurementsParamsIds.procurementIds.length === currentIndex + 2;
            setButtonNavigate(prevState => ({ ...prevState, next: false, firstElement: false, lastElement, isLoading: false }));
        }
        else {
            if (pagination.currentPage === pagination.totalPages) {
                setButtonNavigate(prevState => ({ ...prevState, next: false, lastElement: true, isLoading: false }));
                return;
            }
            const formValues = (0, helpers_1.transformUrlToFilterParams)(location.search);
            const response = await getProcurementIds({ ...formValues, page: pagination.currentPage + 1 });
            if (response && response.procurementIds[0]) {
                const CorrectionOrEdit = response.procurementIds[0].status === types_1.ProcurementStatuses.CORRECTED
                    || response.procurementIds[0].status === types_1.ProcurementStatuses.CORRECTION_PROCESS
                    ? '/corrections'
                    : '/edit';
                formValues.page = response.meta.pagination.currentPage;
                const itemsParams = (0, helpers_1.getItemsParamsFromUrl)(location.search);
                const urlParams = { ...(0, helpers_1.transformFilterParamsToUrl)(formValues), page: response.meta.pagination.currentPage, ...itemsParams };
                navigate('/documents/purchases/procurements/' + response.procurementIds[0].id + CorrectionOrEdit + (0, queryString_1.stringify)(urlParams));
                const lastElement = response.meta.pagination.currentPage === response.meta.pagination.totalPages && response.procurementIds.length === 1;
                setButtonNavigate(prevState => ({ ...prevState, next: false, firstElement: false, lastElement, isLoading: false }));
            }
        }
    };
    const previousProcurement = async () => {
        setButtonNavigate(prevState => ({ ...prevState, previous: true, isLoading: true }));
        const currentIndex = procurementsParamsIds.procurementIds.findIndex(el => el.id === props.procurementId);
        const prevProcurement = procurementsParamsIds.procurementIds[currentIndex - 1];
        const { pagination } = procurementsParamsIds.meta;
        const params = (0, helpers_1.transformFilterParamsToUrl)((0, helpers_1.transformUrlToFilterParams)(location.search));
        // const CorrectionOrEdit = Number(params.statusId) === ProcurementStatuses.CORRECTED
        // || Number(params.statusId) === ProcurementStatuses.CORRECTION_PROCESS ? '/corrections' : '/edit'
        if (prevProcurement) {
            const CorrectionOrEdit = prevProcurement.status === types_1.ProcurementStatuses.CORRECTED
                || prevProcurement.status === types_1.ProcurementStatuses.CORRECTION_PROCESS
                ? '/corrections'
                : '/edit';
            const itemsParams = (0, helpers_1.getItemsParamsFromUrl)(location.search);
            navigate('/documents/purchases/procurements/' + prevProcurement.id + CorrectionOrEdit + (0, queryString_1.stringify)({ ...params, ...itemsParams }));
            const firstElement = pagination.currentPage === 1 && currentIndex === 1;
            setButtonNavigate(prevState => ({ ...prevState, previous: false, lastElement: false, firstElement, isLoading: false }));
        }
        else {
            if (pagination.currentPage === 1) {
                setButtonNavigate(prevState => ({ ...prevState, previous: false, firstElement: true, isLoading: false }));
                return;
            }
            const formValues = (0, helpers_1.transformUrlToFilterParams)(location.search);
            const response = await getProcurementIds({ ...formValues, page: pagination.currentPage - 1 });
            if (response) {
                const previousProcurement = response.procurementIds[response.procurementIds.length - 1];
                if (previousProcurement) {
                    const CorrectionOrEdit = previousProcurement.status === types_1.ProcurementStatuses.CORRECTED
                        || previousProcurement.status === types_1.ProcurementStatuses.CORRECTION_PROCESS
                        ? '/corrections'
                        : '/edit';
                    formValues.page = response.meta.pagination.currentPage;
                    const itemsParams = (0, helpers_1.getItemsParamsFromUrl)(location.search);
                    const urlParams = { ...(0, helpers_1.transformFilterParamsToUrl)(formValues), page: response.meta.pagination.currentPage, ...itemsParams };
                    navigate('/documents/purchases/procurements/' + previousProcurement.id + CorrectionOrEdit
                        + (0, queryString_1.stringify)(urlParams));
                    const firstElement = response.meta.pagination.currentPage === 1 && currentIndex === 1;
                    setButtonNavigate(prevState => ({ ...prevState, previous: false, lastElement: false, firstElement, isLoading: false }));
                }
            }
        }
    };
    const getLastFirstElementStatus = (id) => {
        const params = (0, helpers_1.transformUrlToFilterParams)(location.search);
        params.onlyMine = localStorage.getItem('procurementOnlyMine') || undefined;
        getProcurementIds(params).then(response => {
            if (response) {
                const { currentPage, totalPages } = response.meta.pagination;
                const procurementIndex = response.procurementIds.findIndex(el => el.id === id || el.id === props.procurementId);
                if (procurementIndex === 0 && currentPage === 1)
                    setButtonNavigate(prevState => ({ ...prevState, firstElement: true }));
                if (currentPage === totalPages && procurementIndex === response.procurementIds.length - 1) {
                    setButtonNavigate(prevState => ({ ...prevState, lastElement: true }));
                }
            }
        });
    };
    (0, react_1.useEffect)(() => {
        if (props.procurementId)
            getLastFirstElementStatus(props.procurementId);
        isMounted.current = true;
        return () => { isMounted.current = false; };
    }, []);
    (0, react_1.useEffect)(() => { if (props.procurementId)
        getProcurement().then(); }, [props.procurementId]);
    (0, react_1.useEffect)(() => {
        if (props.authUserId && state.data) {
            setIsApprover(() => state.data.approvers.some((el) => el.id === props.authUserId && !el.deletedAt));
            const approvalUser = state.data.approvers.some((el) => el.id === props.authUserId && el.status !== types_1.ApproverStatus.APPROVED && !el.deletedAt);
            const declineUser = state.data.approvers.some((el) => el.id === props.authUserId && el.status !== types_1.ApproverStatus.DECLINED && !el.deletedAt);
            const suspendUser = state.data.approvers.some((el) => el.id === props.authUserId && el.status !== types_1.ApproverStatus.SUSPENDED && !el.deletedAt);
            if ((!declineUser && showDeclineButton) || state.data.status !== types_1.ProcurementStatuses.APPROVAL_PROGRESS)
                setShowDeclineButton(false);
            if ((!approvalUser && showApprovalButton) || state.data.status !== types_1.ProcurementStatuses.APPROVAL_PROGRESS)
                setShowApprovalButton(false);
            if ((!suspendUser && showSuspendButton) || state.data.status !== types_1.ProcurementStatuses.APPROVAL_PROGRESS)
                setShowSuspendButton(false);
            if (state.data.status === types_1.ProcurementStatuses.APPROVAL_PROGRESS) {
                setShowApprovalButton(approvalUser);
                setShowDeclineButton(declineUser);
                setShowSuspendButton(suspendUser);
            }
        }
    }, [state.data, props.authUserId]);
    (0, react_1.useEffect)(() => {
        return () => {
            clearInterval(lockInterval && lockInterval.current);
            if (lockInterval.current && props.procurementId && documentLock?.status) {
                lockInterval.current = undefined;
                const lockInParams = { moduleName: state.data.moduleType, moduleId: props.procurementId };
                index_1.LockApi.lockOut(lockInParams).then();
                setDocumentLock(null);
            }
        };
    }, [documentLock?.status, props.authUserAccountId, props.procurementId, state.data.status]);
    return {
        state,
        sendForVerify,
        sendApproval,
        addUpdateTreasuryBudgetArticle,
        addUpdateTreasuryProject,
        showRevertMessage,
        documentLockIn,
        documentLockOut,
        getProcurement,
        setShowRevertMessage,
        revertProcurement,
        documentLock,
        setShowDeclineMessage,
        setShowSuspendMessage,
        showSuspendMessage,
        showSuspendButton,
        suspendProcurement,
        showApprovalButton,
        declineProcurement,
        showDeclineMessage,
        showDeclineButton,
        isApprover,
        updateProcurement,
        createProcurement,
        attachSignature,
        sendSignatureDocument,
        attachSignedDocument,
        deleteProcurementItem,
        deletingItems,
        setShowSingleApprovalAlert,
        showSingleApprovalAlert,
        openedCompanySidebar,
        setCompanySideBar,
        setDeletingItems,
        isDeletingItemsLoading,
        closeErrorMessage,
        showDeleteFileAlert,
        setShowDeleteFileAlert,
        deletingFiles,
        deleteProcurementFile,
        procurementFileType,
        setProcurementFileType,
        itemsDirty,
        setItemsDirty,
        dispatch,
        setHasItems,
        hasItems,
        nextProcurement,
        previousProcurement,
        buttonNavigate,
        listingParams,
        showActuallyDoneMessage,
        setShowActuallyDoneMessage,
        actuallyDoneProcurement,
        updateProcurementTemplate,
        getTemplate,
        generateProcurementTemplate,
        isMounted
    };
};
exports.default = useAddEditProcurement;
