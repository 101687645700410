"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const procurementItemsStore_1 = require("../store/procurementItemsStore");
const index_1 = require("api/index");
const helpers_1 = require("core/helpers");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const helpers_2 = require("../helpers");
const queryString_1 = require("core/helpers/queryString");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const useItemsForProcurement = (props) => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const [keyword, setKeyword] = (0, react_1.useState)('');
    const [state, dispatch] = (0, procurementItemsStore_1.useProcurementItemsV2Reducer)();
    const [showVerifiedItems, setShowVerifiedItems] = (0, react_1.useState)(false);
    const [showInvoices, setShowInvoices] = (0, react_1.useState)(false);
    const getProcurementItems = async (params) => {
        const itemsParams = (0, helpers_2.transformProcurementItemsParams)(params);
        const procurementParams = (0, helpers_2.transformFilterParamsToUrl)((0, helpers_2.transformUrlToFilterParams)(location.search));
        dispatch({ type: 'SEND_PROCUREMENT_ITEMS_REQUEST' });
        const procurementItems = await index_1.ProcurementsApi.getItemsForProcurement(props.procurementCorrectionId ? props.procurementCorrectionId : props.procurementId, itemsParams);
        if (props.isMounted.current)
            navigate((0, queryString_1.stringify)({ ...params, ...procurementParams }));
        dispatch({ type: 'DONE_PROCUREMENT_ITEMS_REQUEST', payload: { items: procurementItems, procurementSumPrice: props.procurementSumPrice } });
    };
    const selectPerPage = (itemPerPage) => {
        const params = (0, queryString_1.parse)(location.search);
        dispatch({ type: 'CHANGE_PER_PAGE', payload: itemPerPage });
        getProcurementItems({ ...params, itemPerPage, itemPage: undefined }).then();
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    const debouncedSearch = (value) => {
        setKeyword(value);
        const params = {
            ...(0, helpers_2.getItemsParamsFromUrl)(location.search),
            itemsKeyword: value,
            itemPage: 1
        };
        (0, debouncedMethods_1.debouncedOnFunc)(params, getProcurementItems);
    };
    const navigatePagination = (page) => {
        // const params = parse(location.search) as ItemsForProcurementParams
        const itemPage = Math.ceil(state.meta.pagination.total / state.meta.pagination.perPage) >= page ? page : page - 1;
        const params = { ...(0, helpers_2.getItemsParamsFromUrl)(location.search), itemPage };
        getProcurementItems(params).then();
    };
    const updateProcurementItemsList = async (id, values) => {
        const params = (0, helpers_2.transformProcurementItemsListParams)(values, props.reasons);
        dispatch({ type: 'SEND_ITEMS_LIST_UPDATE_REQUEST' });
        const procurementItems = await index_1.ProcurementsApi.updateProcurementItem(id, params);
        dispatch({ type: 'DONE_ITEMS_LIST_UPDATE_REQUEST', payload: procurementItems });
        return procurementItems;
    };
    const updatePagination = (deletedItemCount) => {
        const itemPage = (0, helpers_1.calculateCurrentPage)(state.meta.pagination, deletedItemCount);
        if (itemPage) {
            const params = (0, helpers_2.getItemsParamsFromUrl)(location.search);
            getProcurementItems({ ...params, itemPage }).then();
        }
        else {
            dispatch({ type: 'UPDATE_PAGINATION_WHEN_DELETING', payload: deletedItemCount });
        }
    };
    (0, react_1.useEffect)(() => {
        const params = (0, helpers_2.getItemsParamsFromUrl)(location.search);
        const paginatedStorage = JSON.parse(localStorage.getItem('paginated') || '""');
        const storagePerPage = paginatedStorage?.procurementItems?.perPage;
        params.itemPerPage = params.itemPerPage || Number(storagePerPage) || 50;
        dispatch({ type: 'CHANGE_PER_PAGE', payload: params.itemPerPage });
        setKeyword(params.itemsKeyword || '');
        if (props.procurementCorrectionId || props.procurementId)
            getProcurementItems(params).then();
    }, [props.procurementCorrectionId, props.procurementId]);
    (0, react_1.useEffect)(() => {
        dispatch({ type: 'CALCULATE_SUM_ON_OTHER_PAGE', payload: props.procurementSumPrice });
        // console.log(props.procurementSumPrice)
    }, [props.procurementSumPrice]);
    return {
        state,
        updateProcurementItemsList,
        selectPerPage,
        keyword,
        debouncedSearch,
        navigatePagination,
        showVerifiedItems,
        setShowVerifiedItems,
        showInvoices,
        setShowInvoices,
        updatePagination,
        closeErrorMessage
    };
};
exports.default = useItemsForProcurement;
