"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSubmissionItemsFormReducer = void 0;
const react_1 = require("react");
const store_1 = require("core/store");
const initialSubmissionItemsFormStore = {
    data: [],
    status: null,
    isFetching: false,
    isDeleting: false,
    isUpdatingItemsList: false,
    meta: store_1.initialMeta,
    errors: null
};
const useSubmissionItemsFormReducer = () => {
    return (0, react_1.useReducer)(submissionItemsFormReducer, initialSubmissionItemsFormStore);
};
exports.useSubmissionItemsFormReducer = useSubmissionItemsFormReducer;
const submissionItemsFormReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_SUBMISSION_ITEMS_FORM_REQUEST':
            return {
                ...state,
                isFetching: true
            };
        case 'DONE_SUBMISSION_ITEMS_FORM_REQUEST':
            return {
                ...state,
                data: action.payload.data || [],
                status: action.payload.status,
                errors: action.payload.errors,
                meta: action.payload.meta || store_1.initialMeta,
                isFetching: false
            };
        case 'SEND_SUBMISSION_ITEM_DELETE_REQUEST': {
            return {
                ...state,
                isDeleting: true
            };
        }
        case 'DONE_SUBMISSION_ITEM_DELETE_REQUEST': {
            return {
                ...state,
                // data: state.data.filter(el => !action.payload.includes((el.id as ID))),
                meta: {
                    ...state.meta,
                    pagination: {
                        ...state.meta.pagination,
                        total: state.meta.pagination.total - action.payload.length,
                        count: state.meta.pagination.count - action.payload.length
                    }
                },
                isDeleting: false
            };
        }
        case 'SEND_ITEMS_LIST_UPDATE_REQUEST':
            return {
                ...state,
                errors: null,
                isUpdatingItemsList: true
            };
        case 'DONE_ITEMS_LIST_UPDATE_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                isUpdatingItemsList: false
            };
        case 'UPDATE_STATE_ITEMS':
            return {
                ...state,
                data: action.payload
            };
        case 'CHANGE_PER_PAGE':
            return {
                ...state,
                meta: {
                    ...state.meta,
                    pagination: {
                        ...state.meta.pagination,
                        perPage: action.payload
                    }
                }
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
